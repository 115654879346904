import React, { Component } from "react"
import { Link } from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from "../components/layout"
import SEO from "../components/seo"

import SimpleModal from '../components/utilities/SimpleModal';

import "./casestudycitrixgoogleccm.scss"

import imgQuote from "../images/quotation_mark.webp"

export default ({ pageContext }) => (
    <Layout>
        <SEO
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image}
            link={pageContext.link}
        />
        <div className="case-study-cgccm-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="header-container"
                        style={{
                            backgroundImage: `url(${pageContext.acf.header_image.csOneHedImaLocalFile.childImageSharp.fluid.src})`,
                        }}
                    >
                        <img src={imgQuote} alt="" />
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">                   
                    <div className="col-md-8">
                       <img
                            src={
                                pageContext.acf.client_logo.csOneCliLogLocalFile.childImageSharp
                                    .fluid.src
                            }
                            className="client-logo"
                            width={pageContext.acf.client_logo.media_details.width / 1.5}
                            height={pageContext.acf.client_logo.media_details.height / 1.5}
                            alt={pageContext.yoast.opengraph_title}
                        />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2022/11/citrix-and-chrome-earned-media-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                    <div className="col-12">
                        <div
                            className="first-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.first_paragraph,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="fullWidthGrayCTA margin-b">
                <div class="container detail-wrapper">
                    <div className="row">
                        <div className="col-12">
                            <h3>Challenge</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <p>Desktop as a Service (DaaS) is cloud-based technology that is gaining significant traction among IT leaders, but many in the C-Suite are still trying to understand its benefits to the bottom line of the organization. Citrix, Google Cloud, and ChromeOS are growing category leaders for DaaS, particularly as remote workforces have grown significantly since the pandemic. The two companies have built significant momentum in developing a <a href="/case-studies/citrix-chromeos-vertical-marketing/">vertically focused</a> marketing and sales strategy within specific markets.</p>
                        </div>
                        <div className="col-md-6">
                            <p>Wanting to augment with an educational thought leadership component, Merit Mile implemented an integrated PR awareness campaign designed to mirror this vertical approach in telling the story of Citrix, Google Cloud, and ChromeOS and the benefits of DaaS across specific industries. The successful integration and addition of an awareness campaign requires a deep understanding of pivoting a sales-focused marketing message into an earned media, educational narrative that tells the story of why C-Suite executives must pay closer attention.</p>                        
                        </div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div
                            className="third-paragraph"
                            dangerouslySetInnerHTML={{
                                __html: pageContext.acf.third_paragraph,
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h3>Results</h3>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-md-6">
                        <p>The Merit Mile PR team executed its series of industry outreach programs for earned media awareness to contact center and healthcare publications and websites, landing Citrix multiple bylined articles. Key placements included <a href="https://www.channelpronetwork.com/article/secure-daas-solutions-help-remote-contact-center-agents?page=0%2C0" target="_blank" rel="noopener noreferer">ChannelPro</a>, Contact Center Pipeline, and <a href="https://www.healthcarebusinesstoday.com/how-is-secure-desktop-as-a-service-daas-reshaping-the-way-healthcare-organizations-operate/" target="_blank" rel="noopener noreferer">Healthcare Business Today</a>.</p>
                        <p>The team also secured analyst briefing requests from leading IT research firms such as Gartner, VDC Research, and ESG-Global. These briefings were key in supplementing the vertical and IT trade media placements to inject Citrix and Google deeper into the minds of today’s top analysts covering these important topics.</p>
                    </div>
                    <div className="col-md-6">
                        <img
                            src="https://www.meritmile.com/uploads/2022/08/cccs-webscreens.jpg"
                            className="results-image img-fluid"
                            alt="Citrix Google Contact Center Marketing Case Study Results"
                            width="616px"
                        />
                    </div>
                </div>
            </div>
            <div className="fullWidthGrayCTA">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h5>Learn more about how Merit Mile can support your earned media, editorial storytelling and analyst research goals by contacting <a href="mailto:Hello@MeritMile.com?subject=Citrix%20%20Google%20Contact%20Center%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>hello@meritmile.com.</b></a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="yourteam-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Your Merit Mile Team</h3>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-12">
                            <div className="team-cards">
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/js-thumb.jpg"
                                        alt="John Sternal"
                                        className="img-fluid"
                                    />
                                    <h4>John Sternal</h4>
                                    <p>
                                        Partner, PR,<br />
                                        Social, Research<br />
                                        &nbsp;
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sl-thumb.jpg"
                                        alt="Scott Lindars"
                                        className="img-fluid"
                                    />
                                    <h4>Scott Lindars</h4>
                                    <p>
                                        Director<br />
                                        Marketing +<br />
                                        Business Strategy
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/sd-thumb.jpg"
                                        alt="Saul Darville"
                                        className="img-fluid"
                                    />
                                    <h4>Saul Darville</h4>
                                    <p>
                                        Executive<br />
                                        Creative<br />
                                        Director
                                    </p>
                                </div>
                                <div className="team-card">
                                    <img
                                        src="https://www.meritmile.com/uploads/2022/08/mc-thumb.jpg"
                                        alt="Mitch Curry"
                                        className="img-fluid"
                                    />
                                    <h4>Mitch Curry</h4>
                                    <p>
                                        Project<br />
                                        Manager<br />
                                        &nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:Hello@MeritMile.com?subject=Citrix%20%20Google%20Contact%20Center%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)
